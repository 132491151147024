import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {TransactionsModel} from "../../transactions/transactions.model";
import TransactionsModalStore from "./transactions.modal.store";
import TransactionsStore from "../../transactions/transactions.store";
import React, {useEffect, useRef, useState} from "react";
import AppStore from "../../../app.store";
import UsersStore from "../../users/users.store";
import BusinessUnitsStore from "../../business_units/business_units.store";
import ClientsStore from "../../clients/clients.store";
import AccountStore from "../../accounts/account.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import {Text} from "../../../styles/text";
import {borderRadius, inputBackground, inputColor, modalTextColor, primaryColor} from "../../../utils/variables";
import {SelectView, TextInput} from "../../../styles/form";
import {hasPermission} from "../../../utils/hasPermission";
import {Badge, Divider, Input, Select, Space, Switch, Tooltip} from "antd";
import {Table, TableBody, TableContainer, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {Button} from "../../../styles/button";
import CreateOperationStore from "../create.operation.modal/create.operation.store";
import {TransactionsModalModel} from "./transactions.modal.model";
import {runInAction} from "mobx";
import {Image} from "../../../styles/image";
import InfoIcon from "../../../assets/icons/information.svg";
import ModalView from "../../../components/modal/modal.view";
import TransactionsModalSerialsView from "./transactions.modal.serials.view";
import ExchangeRatesStore from "../../exchange.rates/exchange.rates.store";

interface TransactionsModalViewProps {
    initialValue: TransactionsModel | null;
    setModal: (isOpen: boolean) => void;
}

const TransactionsModalView = observer(({setModal, initialValue}: TransactionsModalViewProps) => {

    const {
        form,
        destroyTransactionModal,
        loading,
        productList,
        setFormField,
        addProductToList,
        deleteProductFromList,
        createOperationReq,
        initCreateOperation,
        paymentMethods,
        paymentMethodsReq
    } = TransactionsModalStore;
    const {servicesReq, stocksReq, services, stocks, serviceAccountsReq, serviceAccounts} = CreateOperationStore;
    const {permissions, businessSettings} = AppStore;
    const {users} = UsersStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;
    const {clients, setClientsFilter} = ClientsStore;
    const {transactionsReq} = TransactionsStore;
    const {accounts, accountsReq} = AccountStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const inputRef = useRef(null);
    const [goodsTable, setGoodsTable] = useState(true);
    const [serviceTable, setServiceTable] = useState(false);
    const [serialsModal, setSerialsModal] = useState(false);
    const [prodInitialValues, setProdInitialValues] = useState<TransactionsModalModel | null>(null);
    const [prodIndex, setProdIndex] = useState(0);
    const {getExchangeRatesReq, exchangeRates, clearExchangeRateFilters} = ExchangeRatesStore;
    const [exchangeRate, setExchangeRate] = useState(1);

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void initCreateOperation(initialValue)
        void dictionaryListReq('TRANSACTIONS', 'MODAL', controller.signal);
        setBusinessUnitFilter({page: 1, page_limit: 100000, return_balance: false});
        void paymentMethodsReq(controller.signal);
        setClientsFilter({page: 1, page_limit: 10000, technical: true});
        void getExchangeRatesReq(controller.signal);
        clearExchangeRateFilters()


        return () => {
            controller.abort();
        }
    }, [
        initCreateOperation,
        destroyModalDictionary,
        dictionaryListReq,
        setBusinessUnitFilter,
        paymentMethodsReq,
        setClientsFilter,
        servicesReq,
        stocksReq,
    ]);

    const handleExchangeRate = () => {
        const rate = exchangeRates?.find(
            (rate) =>
                rate.base_currency === businessSettings?.selling_currency &&
                rate.target_currency === businessSettings?.main_currency
        )?.rate;
        setExchangeRate(rate || 1); // Default to 1 if no rate is found

        runInAction(() => {
            form.rate = rate ? rate : 1;
        })
    }

    const getBuID = () => {
        if (hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE')) {
            runInAction(() => {
                form.bu_id = businessUnits![0].id
            })
        }
        return form.bu_id;
    };

    useEffect(() => {
        const controller = new AbortController();
        handleExchangeRate();
        void servicesReq(controller.signal);
        void stocksReq(controller.signal, getBuID());
    }, [exchangeRates, businessSettings?.selling_currency, businessSettings?.main_currency]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const clientsOptions = clients?.map(
        ({id, full_name}) => ({
            label: full_name,
            value: id,
        }));

    const paymentMethodOptions = paymentMethods?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const handleClientChange = (key: string, value: any) => {
        const controller = new AbortController();
        void accountsReq(true, 'client', value, controller.signal);
        setFormField(key, value);
    };

    // барои ролики мышкара неактивный кардан вакте ки инпут типш number-ай (наверное хубтарай вес проектда як чода монемша)
    // чтобы случайно ракам иваз нашава вакте ролики мышкара истифода мебара барои болову поён кардан
    document.body.addEventListener("wheel", (ev) => {
        const target = ev.composedPath()[0] as HTMLElement;
        if (target.matches('input[type="number"]')) {
            target.blur();
        }
    });

    // commission types
    const onlyPercentage = 1
    const onlyFixed = 2
    const fixedAmountAndPercentageWithLimit = 3 // gets fixed amount of commission if amount is less than limit, else gets percentage commission
    const doubleFixedWithLimit = 4 // two given commissions are fixed
    //doublePercentageWithLimit = 4 // two given commissions are percentage

    // Function to generate regex for the range between min and max
    const generateRangeRegex = (min: number, max: number): string => {
        /*
        if (min < 0 || max < 0 || min > max) {
            throw new Error('Invalid range. Ensure min is >= 0 and min <= max');
        }
        */

        // Function to escape numbers for use in regex
        const escapeNumber = (num: number): string => {
            return num?.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };

        // Handle specific cases for regex
        if (min === 0 && max === 0) {
            return `^0$`; // only match zero
        }

        if (min === max) {
            return `^${escapeNumber(min)}$`; // only match the exact number
        }

        const minLength = min.toString().length;
        const maxLength = max.toString().length;

        let regexParts: string[] = [];

        for (let i = minLength; i <= maxLength; i++) {
            let pattern = '';
            if (i === minLength) {
                pattern = `(${escapeNumber(min)}|\\d{${i}})`;
            } else if (i === maxLength) {
                pattern = `(\\d{${i - 1}}[0-${max.toString().charAt(0)}])`;
            } else {
                pattern = `\\d{${i}}`;
            }

            regexParts.push(pattern);
        }

        return `^(${regexParts.join('|')})$`;
    };

    const handleCommission = (product: any) => {
        runInAction(() => {
            switch (product.commission_type_id) {
                case onlyPercentage:
                    product.commission_amount = product.amount * product.commission / 100;
                    break;

                case onlyFixed:
                    product.commission_amount = product.fixed_commission;
                    break;

                case fixedAmountAndPercentageWithLimit:
                    if (product.amount <= product.commission_limit) {
                        product.commission_amount = product.commission_before_limit;
                    } else {
                        product.commission_amount = Number(product.amount * product.commission_after_limit / 100);
                    }
                    break;

                case doubleFixedWithLimit:
                    if (product.amount <= product.commission_limit) {
                        product.commission_amount = product.commission_before_limit;
                    } else {
                        product.commission_amount = product.commission_after_limit;
                    }
                    break;
                default:
                    break;
            }

            if (product.commission_from_amount) {
                product.amount_with_commission = product.amount - product.commission_amount;
            } else {
                product.amount_with_commission = product.amount + product.commission_amount;
            }

            if (product.commission_amount % 1 > 0) {
                product.commission_amount = product.commission_amount.toFixed(2);
            }

            if (product.amount_with_commission % 1 > 0) {
                product.amount_with_commission = product.amount_with_commission.toFixed(2);
            }
        })
    }


    const handleSubmit = async (confirmDuplicateOperation: boolean) => {
        const response = await createOperationReq(!!initialValue, confirmDuplicateOperation)

        if (response) {
            setModal(false);
            destroyTransactionModal();
            await transactionsReq();
        }
    }

    const stocksOptions = stocks?.map(
        ({
             name,
             brand,
             goods_id,
             quantity,
             has_serial,
             part_number,
             set_price,
         }) => ({
            label: `${part_number} | ${name} | ${brand}`,
            value: goods_id,
            quantity_in_hand: quantity,
            quantity: 1,
            summ: 0,
            totalSumm: set_price,
            total_amount: set_price,
            type: 'goods',
            has_serial: has_serial,
            part_number: part_number,
            set_price: set_price,
            name: name,
            product_id: goods_id,
            brand: brand,
            id: goods_id,
            recommended_price: set_price,
            selling_currency_price: Number((set_price / form.rate).toFixed(2)),
            total_amount_selling_currency: Number((set_price / form.rate).toFixed(2)),
        })
    )

    const servicesOptions = services?.map(
        ({
             service_name,
             brand,
             service_id,
             commission,
             commission_limit,
             commission_from_amount,
             commission_before_limit,
             commission_type_id,
             commission_after_limit,
             fixed_commission_amount,
             fixed_commission_limit,
             fixed_commission,
             min,
             max,
         }) => ({
            label: `${service_name}`,
            value: service_id,
            quantity: 1,
            summ: 0,
            totalSumm: 0,
            commission_amount: 0,
            total_amount: 0,
            type: 'service',
            name: service_name,
            product_id: service_id,
            brand: brand,
            min: min,
            max: max,
            id: service_id,
            commission_type_id: commission_type_id!,
            fixed_commission_limit: fixed_commission_limit!,
            fixed_commission_amount: fixed_commission_amount!,
            fixed_commission: fixed_commission!,
            commission: commission!,
            commission_limit: commission_limit!,
            commission_before_limit: commission_before_limit!,
            commission_after_limit: commission_after_limit!,
            account: '',
            amount_with_commission: 0,
            commission_from_amount: commission_from_amount,
            amount: 0,
            price: 0,
            selling_currency_price: 0,
            total_amount_selling_currency: 0
        })
    )

    const handleSelectProduct = (product: TransactionsModalModel) => {
        addProductToList(product);
        setSelectedOption(null);
    }

    const handleBusinessUnitChange = (value: any) => {
        const controller = new AbortController();
        void stocksReq(controller.signal, value);
        setFormField('bu_id', value)
    }

    const handleChangeProductField = (index: number, fieldName: string, value: any) => {
        if (fieldName === 'selling_currency_price') {
            runInAction(() => {
                productList[index].selling_currency_price = value;
                productList[index].set_price = Number(value * form.rate.toFixed(2));
                productList[index].total_amount = productList[index].quantity * productList[index].set_price;
                productList[index].total_amount_selling_currency = productList[index].quantity * productList[index].selling_currency_price;
                form.total_amount_selling_currency = 0
                form.total_amount = 0;

                productList.map((product: any) => {
                    form.total_amount += product.total_amount
                    form.total_amount_selling_currency += product.total_amount_selling_currency;
                })
            })
        } else if (fieldName === 'quantity') {
            runInAction(() => {
                productList[index].quantity = value;
                productList[index].total_amount = productList[index].quantity * productList[index].set_price;
                productList[index].total_amount_selling_currency = productList[index].quantity * productList[index].selling_currency_price;
                form.total_amount_selling_currency = 0;
                form.total_amount = 0;
                form.total_quantity = 0;

                if (productList[index].type === 'goods') {
                    form.total_goods = 0
                    productList.map((product: any) => {
                        form.total_amount += product.total_amount
                        form.total_quantity += product.quantity
                        form.total_amount_selling_currency += product.total_amount_selling_currency;

                        if (product.type === 'goods') {
                            form.total_goods += product.quantity
                        }
                    })
                } else if (productList[index].type === 'service') {
                    form.total_services = 0
                    productList.map((product: any) => {
                        form.total_amount += product.total_amount
                        form.total_quantity += product.quantity
                        form.total_services += product.quantity
                    })
                }
            })
        } else if (fieldName === 'total_amount_selling_currency') {
            runInAction(() => {
                productList[index].total_amount_selling_currency = value;
                productList[index].total_amount = Number(value * form.rate.toFixed(2));
                productList[index].amount = Number(value * form.rate.toFixed(2));
                productList[index].set_price = Number((productList[index].total_amount / productList[index].quantity).toFixed(2));
                productList[index].selling_currency_price = Number((productList[index].total_amount_selling_currency / productList[index].quantity).toFixed(2));
                form.total_amount_selling_currency = 0

                form.total_amount = 0;
                productList?.map((product: any) => {
                    form.total_amount += product.total_amount
                    form.total_amount_selling_currency += product.total_amount_selling_currency;
                })
            })
        }
    }

    const handleServiceOptionChange = (e: any, product: any) => {
        product.account = e;
    };

    const handleGoodsTableClick = () => {
        setGoodsTable(true);
        setServiceTable(false);
    }

    const handleServiceTableClick = () => {
        setGoodsTable(false);
        setServiceTable(true);
    }

    const handleClickSerials = (product: any, index: number) => {
        setProdInitialValues(product);
        setSerialsModal(true);
        setProdIndex(index);
    }

    const handleCloseSerialModal = () => {
        setSerialsModal(false);
        setProdInitialValues(null);
        setProdIndex(0);
    }

    const renderGoodsTable = () => {
        return (
            <>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell style={{width: '25%'}}>Наименование товара</THeadCell>
                        <THeadCell style={{width: '7%'}}>Бренд</THeadCell>
                        <THeadCell style={{width: '3%'}}>В наличии</THeadCell>
                        <THeadCell style={{width: '8%'}}>К-во</THeadCell>
                        <THeadCell style={{width: '10%'}}>
                            {`Цена ${businessSettings?.multicurrency ? `(${businessSettings?.selling_currency})` : ''}`}
                        </THeadCell>
                        <THeadCell
                            style={{width: '15%'}}>{`Общая сумма ${businessSettings?.multicurrency ? `(${businessSettings?.selling_currency})` : ''}`}</THeadCell>
                        {businessSettings?.multicurrency && (
                            <>
                                <THeadCell
                                    style={{width: '5%'}}>{`Цена (${businessSettings?.main_currency})`}</THeadCell>
                                <THeadCell
                                    style={{width: '5%'}}>{`Общая сумма (${businessSettings?.main_currency})`}</THeadCell>
                            </>
                        )}
                        <THeadCell style={{width: '2%'}}></THeadCell>
                    </TRow>
                </TableHead>
                <TableBody>
                    {productList?.map((product, index) => (
                        product.type === 'goods' && (
                            <TRow key={product.name + index} menu>
                                <TCell>{product.name}</TCell>
                                <TCell>{product.brand}</TCell>
                                <TCell>
                                    <Text color={product.quantity_in_hand > product.quantity ? 'unset' : 'red'}>
                                        {product.quantity_in_hand}
                                    </Text>
                                </TCell>
                                <TCell>
                                    <Layout widthSize={'100%'} vertical>
                                        <TextInput
                                            style={{width: '100%'}}
                                            value={product.quantity}
                                            name={'quantity'}
                                            onChange={(e) => {
                                                handleChangeProductField(index, 'quantity', +e.target.value)
                                            }}
                                        />
                                        {product.has_serial &&
                                            <Text
                                                color={primaryColor}
                                                extent={'13px'}
                                                style={{fontStyle: 'italic', cursor: 'pointer'}}
                                                onClick={() => handleClickSerials(product, index)}
                                            >
                                                Серийные номера
                                            </Text>
                                        }
                                    </Layout>
                                </TCell>
                                <TCell>
                                    <TextInput
                                        style={{width: '100%'}}
                                        value={product.selling_currency_price}
                                        name={'selling_currency_price'}
                                        onChange={(e) => {
                                            handleChangeProductField(index, 'selling_currency_price', +e.target.value)
                                        }}
                                    />
                                </TCell>
                                <TCell>
                                    <TextInput
                                        style={{width: '100%'}}
                                        value={product.total_amount_selling_currency}
                                        name={'total_amount_selling_currency'}
                                        onChange={(e) => {
                                            handleChangeProductField(index, 'total_amount_selling_currency', +e.target.value)
                                        }}
                                    />
                                </TCell>
                                {businessSettings?.multicurrency && (
                                    <>
                                        <TCell>{(product.set_price).toFixed(2)}</TCell>
                                        <TCell>{(product.total_amount).toFixed(2)}</TCell>
                                    </>
                                )}
                                <TCell>
                                    <Button onClick={() => deleteProductFromList(index)}>
                                        -
                                    </Button>
                                </TCell>
                            </TRow>
                        )))}
                    <TRow>
                        <TCell colSpan={3}>
                            <Select
                                showSearch
                                options={stocksOptions}
                                showArrow={false}
                                filterOption={(inputValue, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                                }
                                placeholder={'Выберите товар'}
                                onChange={(_, option) => {
                                    handleSelectProduct(option)
                                }}
                                style={{
                                    width: '100%',
                                }}
                                value={selectedOption}
                            />
                        </TCell>
                        <TCell></TCell>
                        <TCell></TCell>
                        <TCell></TCell>
                        {businessSettings?.multicurrency && (
                            <>
                                <TCell></TCell>
                                <TCell></TCell>
                            </>
                        )}
                        <TCell></TCell>
                    </TRow>
                </TableBody>
            </>
        );
    }

    const renderServiceTable = () => {
        return (
            <>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell style={{width: '35%'}}>Наименование услуги</THeadCell>
                        <THeadCell
                            style={{width: '25%'}}>{getModalPlaceholders('account')}
                        </THeadCell>
                        {businessSettings?.product_commissions && (
                            <>
                                {businessSettings?.product_reverse_commission && (
                                    <THeadCell style={{width: '5%'}}>
                                        Вычесть комиссию из суммы
                                    </THeadCell>
                                )}
                                <THeadCell
                                    style={{width: '5%'}}>{getModalPlaceholders('commission_amount')}</THeadCell>
                                <THeadCell
                                    style={{width: '5%'}}>{getModalPlaceholders('total_amount_com')}</THeadCell>
                            </>
                        )}
                        <THeadCell style={{width: '20%'}}>Общая сумма</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                <TableBody>
                    {productList?.map((product, index) => (
                        product.type === 'service' && (
                            <TRow key={product.name + index} menu>
                                <TCell>{product.name}</TCell>
                                <TCell>
                                    {form.client_id !== 1 ? (
                                        <Layout vertical between={10}>
                                            <Layout widthSize="100%">
                                                <Select
                                                    value={product.account}
                                                    onChange={(e) => {
                                                        handleServiceOptionChange(e, product);
                                                    }}
                                                    onFocus={() => {
                                                        void serviceAccountsReq(
                                                            form.client_id,
                                                            product.id
                                                        );
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder=""
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            <Space
                                                                style={{
                                                                    padding: '0 8px 4px',
                                                                }}
                                                            >
                                                                <Input
                                                                    maxLength={16}
                                                                    placeholder="Введите счет"
                                                                    ref={inputRef}
                                                                    value={product.account}
                                                                    onChange={(e) => {
                                                                        product.account = e.target.value;
                                                                    }}
                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                />
                                                            </Space>
                                                            <Divider
                                                                style={{
                                                                    margin: '8px 0',
                                                                }}
                                                            />
                                                            {menu}
                                                        </>
                                                    )}
                                                    options={serviceAccounts.map((item) => ({
                                                        label: item,
                                                        value: item,
                                                    }))}
                                                />
                                            </Layout>
                                        </Layout>
                                    ) : (
                                        <Layout>
                                            <Input
                                                maxLength={16}
                                                placeholder="Введите счет"
                                                ref={inputRef}
                                                value={product.account}
                                                onChange={(e) => {
                                                    product.account = e.target.value;
                                                }}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                        </Layout>
                                    )}
                                </TCell>
                                {businessSettings?.product_commissions && businessSettings?.product_reverse_commission && (
                                    <TCell>
                                        <Layout>
                                            <Switch
                                                checked={product.commission_from_amount}
                                                onChange={(e) => {
                                                    runInAction(() => {
                                                        product.commission_from_amount = e
                                                    })
                                                    handleCommission(product);
                                                }}
                                            />
                                        </Layout>
                                    </TCell>
                                )}
                                {businessSettings?.product_commissions && (
                                    <TCell>
                                        {product.commission_amount}
                                    </TCell>
                                )}
                                {businessSettings?.product_commissions && (
                                    <TCell>{product.amount_with_commission}</TCell>
                                )}
                                <TCell>
                                    <Layout widthSize={'100%'} vAlign={"center"}>
                                        <TextInput
                                            style={{width: '100%'}}
                                            value={product.total_amount_selling_currency}
                                            pattern={generateRangeRegex(product.min!, product.max!)}
                                            minMaxValueCheck={businessSettings?.product_min_max}
                                            name={'total_amount'}
                                            onChange={(e) => {
                                                handleChangeProductField(index, 'total_amount_selling_currency', +e.target.value)
                                                handleChangeProductField(index, 'amount', +e.target.value)
                                                if (businessSettings?.product_commissions) {
                                                    setTimeout(() => handleCommission(product), 500);
                                                }
                                            }}
                                        />
                                        {businessSettings?.product_min_max &&
                                            <Tooltip
                                                title={`Сумма операции от ${product.min} до ${product.max}`}
                                                color={primaryColor}
                                            >
                                                <Image
                                                    src={InfoIcon}
                                                    height={'18px'}
                                                    width={'18px'}
                                                    style={{
                                                        filter:
                                                            'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    </Layout>
                                </TCell>
                                <TCell>
                                    <Button onClick={() => deleteProductFromList(index)}>
                                        -
                                    </Button>
                                </TCell>
                            </TRow>
                        )))}
                    <TRow>
                        <TCell colSpan={3}>
                            <Select
                                showSearch
                                options={servicesOptions}
                                showArrow={false}
                                filterOption={(inputValue, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                                }
                                placeholder={'Выберите услугу'}
                                onChange={(_, option) => {
                                    handleSelectProduct(option)
                                }}
                                style={{
                                    width: '100%',
                                }}
                                value={selectedOption}
                            />
                        </TCell>

                        <TCell></TCell>
                        {businessSettings?.product_commissions && (
                            <>
                                {businessSettings?.product_reverse_commission && (
                                    <TCell></TCell>
                                )}
                                <TCell></TCell>
                                <TCell></TCell>
                            </>

                        )}

                    </TRow>
                </TableBody>
            </>
        );
    }

    const getReverseRate = () => {
        return (1 / exchangeRate).toFixed(4)
    }

    return (
        <Layout vertical between={20}>
            <Layout hAlign={"space-around"}>
                <Layout widthSize={'30%'}>
                    <Layout vertical between={10} widthSize={'100%'}>
                        <Layout widthSize={'100%'} vAlign="center" hAlign={'space-between'}>
                            <Layout widthSize={'35%'}>
                                <Text extent="S" color={modalTextColor}>
                                    {getModalPlaceholders('client_id')}
                                </Text>
                            </Layout>
                            <Layout widthSize="65%">
                                <Select
                                    showSearch
                                    placeholder="Выберите клиента"
                                    value={form.client_id}
                                    filterOption={(input, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        handleClientChange('client_id', value);
                                    }}
                                    style={{
                                        width: '100%',
                                        border: 'none',
                                        borderRadius: borderRadius,
                                        backgroundColor: inputBackground,
                                        color: inputColor
                                    }}
                                    options={clientsOptions}
                                >
                                </Select>
                            </Layout>
                        </Layout>
                        {businessSettings?.select_employee && hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE') && (
                            <Layout widthSize={'100%'} vAlign="center" hAlign={'space-between'}>
                                <Layout widthSize='35%'>
                                    <Text extent='S' color={modalTextColor}>
                                        {getModalPlaceholders('employee_id')}
                                    </Text>
                                </Layout>
                                <Layout widthSize='65%'>
                                    <SelectView
                                        name="employee"
                                        value={form.employee_id}
                                        onChange={(event) =>
                                            setFormField('employee_id', +event.target.value)
                                        }
                                    >
                                        {users?.map((user, index: number) => (
                                            <option key={index} value={user.id}>
                                                {`${user.name} ${user.surname}`}
                                            </option>
                                        ))}
                                    </SelectView>
                                </Layout>
                            </Layout>
                        )}
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'35%'}>
                                <Text extent="S" color={modalTextColor}>
                                    {getModalPlaceholders('payment_method_id')}
                                </Text>
                            </Layout>
                            <Layout widthSize={'65%'} style={{maxWidth: '100%'}}>
                                <SelectView
                                    extent={'S'}
                                    name="company"
                                    value={form.payment_method_id}
                                    onChange={(event) =>
                                        setFormField('payment_method_id', +event.target.value)
                                    }
                                >
                                    {paymentMethodOptions?.map((option: any, index: any) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </SelectView>
                            </Layout>
                        </Layout>
                        {(form.client_id !== 1 && form.payment_method_id === 3) && (
                            <Layout vAlign="center" hAlign={'space-between'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        {getModalPlaceholders('account_id')}
                                    </Text>
                                </Layout>
                                <Layout widthSize="65%">
                                    <SelectView
                                        name="account"
                                        value={form.account_id}
                                        onChange={(event) =>
                                            setFormField('account_id', event.target.value)
                                        }
                                    >
                                        {accounts?.map((option: any, index: any) => (
                                            <option key={index} value={option.id}>
                                                {option.number}
                                            </option>
                                        ))}
                                    </SelectView>
                                </Layout>
                            </Layout>
                        )}
                        <Layout widthSize={'100%'} vAlign="center" hAlign={'space-between'}>
                            <Layout widthSize="35%">
                                <Text extent="S" color={modalTextColor}>
                                    {getModalPlaceholders('details')}
                                </Text>
                            </Layout>
                            <Layout widthSize="65%">
                                <TextInput
                                    type="text"
                                    name="details"
                                    value={form.details}
                                    onChange={(event) =>
                                        setFormField(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                        {hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE') && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        {getModalPlaceholders('bu_id')}
                                    </Text>
                                </Layout>
                                <Layout widthSize={'65%'} style={{maxWidth: '100%'}}>
                                    <SelectView
                                        extent={'S'}
                                        name="business-unit-options"
                                        value={form.bu_id}
                                        onChange={(event) =>
                                            handleBusinessUnitChange(event.target.value)
                                        }
                                    >
                                        {businessUnitsOptions?.map((option: any, index: any) => (
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </SelectView>
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_phone && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        Номер телефона клиента
                                    </Text>
                                </Layout>
                                <Layout widthSize="50%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_phone"
                                        value={form.paying_client_phone}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_number && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'65%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        Номер паспорта
                                    </Text>
                                </Layout>
                                <Layout widthSize="50%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_passport_number"
                                        value={form.paying_client_passport_number}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_date_of_expiry && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        Дата окончания пасспорта
                                    </Text>
                                </Layout>
                                <Layout widthSize="65%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_passport_date_of_expiry"
                                        value={form.paying_client_passport_date_of_expiry}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_date_of_birth && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        Дата рождения
                                    </Text>
                                </Layout>
                                <Layout widthSize="65%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_date_of_birth"
                                        value={form.paying_client_date_of_birth}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_date_of_issue && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        Дата выдачи паспорта
                                    </Text>
                                </Layout>
                                <Layout widthSize="65%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_passport_date_of_issue"
                                        value={form.paying_client_passport_date_of_issue}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_full_name && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        ФИО клиента
                                    </Text>
                                </Layout>
                                <Layout widthSize="65%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_full_name"
                                        value={form.paying_client_full_name}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_issuing_authority && (
                            <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                <Layout widthSize={'35%'}>
                                    <Text extent="S" color={modalTextColor}>
                                        Кем выдан паспорт
                                    </Text>
                                </Layout>
                                <Layout widthSize="65%">
                                    <TextInput
                                        type="text"
                                        name="paying_client_passport_issuing_authority"
                                        value={form.paying_client_passport_issuing_authority}
                                        onChange={(event) =>
                                            setFormField(event.target.name, event.target.value)
                                        }
                                    />
                                </Layout>
                            </Layout>
                        )}
                        {businessSettings?.multicurrency === true && (
                            <>
                                <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                    <Layout widthSize={'35%'}>
                                        <Text extent="S" color={modalTextColor}>
                                            Основная валюта
                                        </Text>
                                    </Layout>
                                    <Layout widthSize={'65%'} hAlign={"flex-start"}>
                                        <Text extent="S">
                                            {businessSettings?.main_currency!}
                                        </Text>
                                    </Layout>
                                </Layout>
                                <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                    <Layout widthSize={'35%'}>
                                        <Text extent="S" color={modalTextColor}>
                                            Валюта продажи
                                        </Text>
                                    </Layout>
                                    <Layout widthSize={'65%'} hAlign={"flex-start"}>
                                        <Text extent="S">
                                            {businessSettings?.selling_currency!}
                                        </Text>
                                    </Layout>
                                </Layout>
                                <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                                    <Layout widthSize={'35%'}>
                                        <Text extent="S" color={modalTextColor}>
                                            Курс
                                        </Text>
                                    </Layout>
                                    <Layout widthSize={'65%'} vertical between={5} hAlign={"flex-start"}>
                                        <Text extent="S">
                                            {`1 ${businessSettings?.selling_currency!} = ${exchangeRate.toFixed(4)} ${businessSettings?.main_currency}`}
                                        </Text>
                                        <Text extent="S">
                                            {`1 ${businessSettings?.main_currency!} = ${getReverseRate()} ${businessSettings?.selling_currency}`}
                                        </Text>
                                    </Layout>
                                </Layout>
                            </>
                        )}
                    </Layout>
                </Layout>
                <Layout widthSize={'65%'} vertical hAlign={"center"} between={20}>
                    <Layout centered>
                        <Layout vertical between={10}>
                            <Layout vAlign={"center"} between={20} style={{paddingTop: '10px'}}>
                                <Badge count={productList.filter((item) => item.type === 'goods').length}>
                                    <Button
                                        background={goodsTable ? primaryColor : modalTextColor}
                                        onClick={handleGoodsTableClick}
                                    >
                                        <Text weight={goodsTable ? 'bold' : 'unset'}>Товары</Text>
                                    </Button>
                                </Badge>
                                <Badge count={productList.filter((item) => item.type === 'service').length}>
                                    <Button
                                        background={serviceTable ? primaryColor : modalTextColor}
                                        onClick={handleServiceTableClick}
                                    >
                                        <Text weight={serviceTable ? 'bold' : 'unset'}>Услуги</Text>
                                    </Button>
                                </Badge>
                            </Layout>
                        </Layout>
                    </Layout>
                    <TableContainer style={{maxHeight: '45vh'}}>
                        <Table>
                            {goodsTable && (
                                renderGoodsTable()
                            )}
                            {serviceTable && (
                                renderServiceTable()
                            )}
                        </Table>
                    </TableContainer>
                    <Layout widthSize={'100%'} hAlign={"space-around"} vAlign={"center"}>
                        <Layout vAlign={"center"} between={20}
                                widthSize={businessSettings?.multicurrency ? '33%' : '50%'}>
                            <Text color={modalTextColor} extent={'S'}>Общее количество:</Text>
                            <Text> {form.total_quantity} </Text>
                        </Layout>
                        {businessSettings?.multicurrency && (
                            <Layout vAlign={"center"} between={20}
                                    widthSize={businessSettings?.multicurrency ? '33%' : '50%'}>
                                <Text color={modalTextColor} extent={'S'}>
                                    {`Общая сумма${businessSettings?.multicurrency ? ` :(${businessSettings.main_currency})` : `:`}`}
                                </Text>
                                <Text> {form.total_amount.toFixed(2)} </Text>
                            </Layout>
                        )}
                        <Layout vAlign={"center"} between={20}
                                widthSize={businessSettings?.multicurrency ? '33%' : '50%'}>
                            <Text color={modalTextColor} extent={'S'}>
                                {`Общее сумма (${businessSettings?.selling_currency}):`}
                            </Text>
                            <Text> {form.total_amount_selling_currency.toFixed(2)} </Text>
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={() => handleSubmit(false)}
                    disabled={loading || productList.length === 0}
                >
                    {loading ? '' : 'Потвердить'}
                </Button>
            </Layout>
            {serialsModal && (
                <ModalView
                    title={`Серийные номера ${prodInitialValues?.name}`}
                    onClose={handleCloseSerialModal}
                >
                    <TransactionsModalSerialsView
                        initialValue={prodInitialValues}
                        productIndex={prodIndex}
                        setModal={setSerialsModal}
                        businessUnitID={form.bu_id}
                    />
                </ModalView>
            )}
        </Layout>
    );
});

export default TransactionsModalView;