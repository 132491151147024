import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import {Headline} from "../../styles/headline";
import {modalTextColor, primaryColor} from "../../utils/variables";
import OwnerSettingsStore from "./owner.settings.store";
import {Layout} from "../../styles/layout";
import {Image} from "../../styles/image";
import React, {useEffect} from "react";
import {Button} from "../../styles/button";
import Collapse from "../../components/collapse/collapse.view";
import {Text} from "../../styles/text";
import {TextInput} from "../../styles/form";
import {Switch, Tooltip} from "antd";
import InfoIcon from "../../assets/icons/information.svg";

const OwnerSettingsView = observer(() => {
    const {isNavOpen, devices} = AppStore;
    const {
        ownerSettingsPageInit,
        ownerSettings,
        ownerSettingsUpdateLoading,
        changeSettingsReq,
        setOwnerSettingsForm
    } = OwnerSettingsStore;

    useEffect(() => {
        const controller = new AbortController();
        void ownerSettingsPageInit(controller.signal);

        return () => controller.abort();
    }, [ownerSettingsPageInit]);

    const handleConfirmChanges = async () => {
        const controller = new AbortController();
        const response = await changeSettingsReq();

        if (response) {
            await ownerSettingsPageInit(controller.signal);
        }
    }

    const handleChangeSettingsFields = (fieldName: string, value: any) => {
        setOwnerSettingsForm({...ownerSettings, [fieldName]: value})
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20} customScrollbar>
            <Headline color={primaryColor}>Настройки</Headline>
            {/* Основные данные на странице */}
            <Layout vertical>

                <Collapse
                    title={'Основные настройки бизнеса'}
                    description={'Основные настройки и информации бизнеса в целом'}
                    openByDefault
                >
                    <Layout hAlign={"space-around"} vertical={!devices.desktop}>

                        <Layout vertical between={10} widthSize={'60%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout widthSize={'35%'}>
                                    <Text color={modalTextColor} extent={'S'}>Название организации</Text>
                                </Layout>
                                <Layout widthSize={'65%'}>
                                    <TextInput
                                        type={'text'}
                                        value={ownerSettings.name}
                                        onChange={(e) => handleChangeSettingsFields('name', e.target.value)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout widthSize={'35%'}>
                                    <Text color={modalTextColor} extent={'S'}>Номер телефона</Text>
                                </Layout>
                                <Layout widthSize={'65%'}>
                                    <TextInput
                                        type={'text'}
                                        value={ownerSettings.phone}
                                        onChange={(e) => handleChangeSettingsFields('phone', e.target.value)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                    </Layout>
                </Collapse>

                <Collapse
                    title={'Общие настройки'}
                    description={'Общий настройки для контроля бизнеса '}
                    openByDefault
                >
                    <Layout hAlign={"space-around"} vertical={!devices.desktop}>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Бонусная система</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.employee_bonus}
                                        onChange={(e) => handleChangeSettingsFields('employee_bonus', e)}
                                    />
                                </Layout>
                            </Layout>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Мультивалютная система</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.multicurrency}
                                        onChange={(e) => handleChangeSettingsFields('multicurrency', e)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>
                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Рабочее время</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.working_hours}
                                        onChange={(e) => handleChangeSettingsFields('working_hours', e)}
                                    />
                                </Layout>
                            </Layout>
                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Основная валюта</Text>
                                </Layout>
                                <Layout>
                                    <Text extent={'S'}>{ownerSettings.main_currency}</Text>
                                </Layout>
                            </Layout>
                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>
                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Двойная запись чека</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.cheque_double_record}
                                        onChange={(e) => handleChangeSettingsFields('cheque_double_record', e)}
                                    />
                                </Layout>
                            </Layout>
                        </Layout>

                    </Layout>
                </Collapse>

                <Collapse
                    title={'Операции'}
                    description={'Настройки при создании и изменении операции'}
                    openByDefault
                >
                    <Layout hAlign={"space-around"} vertical={!devices.desktop}>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                                <Layout between={2} vAlign={"center"}>
                                    <Text color={modalTextColor} extent={'S'}>Проверка дубликатов операций в
                                        секундах</Text>
                                    <Tooltip
                                        title={'0 означает отключен. Если текущая операция совподает с данными предыдущей, то требуется подтвердить действие'}
                                        color={primaryColor}
                                    >
                                        <Image
                                            src={InfoIcon}
                                            height={'18px'}
                                            width={'18px'}
                                            style={{
                                                filter:
                                                    'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                            }}
                                        />
                                    </Tooltip>
                                </Layout>
                                <Layout>
                                    <TextInput
                                        type={'text'}
                                        value={ownerSettings.duplicate_trn_timeout_seconds}
                                        onChange={(e) => handleChangeSettingsFields('duplicate_trn_timeout_seconds', e.target.value)}
                                    />
                                </Layout>
                            </Layout>

                            <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                                <Layout between={2} vAlign={"center"}>
                                    <Text color={modalTextColor} extent={'S'}>Ограничение возврата операции в
                                        днях</Text>
                                    <Tooltip
                                        title={'0 означает отключен. Дата учитывается с дня создания'}
                                        color={primaryColor}
                                    >
                                        <Image
                                            src={InfoIcon}
                                            height={'18px'}
                                            width={'18px'}
                                            style={{
                                                filter:
                                                    'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                            }}
                                        />
                                    </Tooltip>
                                </Layout>
                                <Layout>
                                    <TextInput
                                        type={'text'}
                                        value={ownerSettings.refund_period_timeout_days}
                                        onChange={(e) => handleChangeSettingsFields('refund_period_timeout_days', e.target.value)}
                                    />
                                </Layout>
                            </Layout>
                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Подтверждение операции</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.transaction_approval}
                                        onChange={(e) => handleChangeSettingsFields('transaction_approval', e)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Обратная комиссия во время операций</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.product_reverse_commission}
                                        onChange={(e) => handleChangeSettingsFields('product_reverse_commission', e)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                    </Layout>
                </Collapse>

                <Collapse
                    title={'Продукты'}
                    description={'Настройки касательно продуктов'}
                    openByDefault
                >
                    <Layout hAlign={"space-around"} vertical={!devices.desktop}>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Комиссия продуктов</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.product_commissions}
                                        onChange={(e) => handleChangeSettingsFields('product_commissions', e)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Размеры упаковок в продуктах</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.product_dimensions}
                                        onChange={(e) => handleChangeSettingsFields('product_dimensions', e)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                        <Layout vertical between={10} widthSize={'30%'}>

                            <Layout hAlign="space-between" vAlign={"center"} between={10}>
                                <Layout>
                                    <Text color={modalTextColor} extent={'S'}>Мин и Макс сумма в продуктах</Text>
                                </Layout>
                                <Layout>
                                    <Switch
                                        checked={ownerSettings.product_min_max}
                                        onChange={(e) => handleChangeSettingsFields('product_min_max', e)}
                                    />
                                </Layout>
                            </Layout>

                        </Layout>

                    </Layout>
                </Collapse>

            </Layout>

            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={ownerSettingsUpdateLoading}
                    onClick={handleConfirmChanges}
                >{ownerSettingsUpdateLoading ? '' : 'Потвердить'}</Button>
            </Layout>
        </Page>
    );
});

export default OwnerSettingsView